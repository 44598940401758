html {
  font-size: 16px
}

body {
  color: #041433;
  margin: 0;
  padding: 0
}

html {
  overflow-y: scroll;
  margin: 0;
  padding: 0
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600
}

.app-container {
  justify-content: space-between;
}

h6.muted {
  color: #6c757d;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 1.5rem;
  font-size: 1rem
}

a {
  color: #0066f9
}

.btn-primary {
  background-color: #0066f9;
  border: 1px solid #005ce0
}

.btn {
  border-radius: .1rem
}
